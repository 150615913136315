body {
  margin: 0;
  padding: 0;
}
/* dashboard stepper */
.step-item {
  @apply relative flex flex-col justify-center items-center w-96
}

.step-item:not(:first-child):before  {
  @apply content-[''] bg-[#F2A900] hover:bg-slate-100 absolute w-full h-[3px] right-2/4 top-1/3 -translate-y-2/4;
}





.step {
  @apply w-8 h-8 flex items-center justify-center z-10 relative bg-gray-500  rounded-full font-semibold text-white;
}


.active .step {
  @apply bg-[#F2A900] w-10 h-10;
  }
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: transparent;
    }

@tailwind base;
@tailwind components;
@tailwind utilities;